import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../../constants/baseUrlApiStatic'
import { DataRTKReturn, DataRTKReturnList } from '../../../interfaces/dataRTKReturn'
import { IRequestConfirmEmail, IRequestLogin, IRequestResetPassword } from './interfaces/IRequestApi'
import { IDataMessage, IDataMessageWithErrors, IDataStatusCodeError, IResponseConfirmEmail, IResponseError, IResponseErrorNotFound, IResponseEx, IResponseLogin } from '../interfaces/IResponseApi'
import { IDefaultResponse } from '../../../interfaces/api/response'
import { ResponseLogin } from '../../../interfaces/responseLogin'
import { RequestLoginUserCode } from '../../../interfaces/api/request'

const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['account'],
  endpoints: (builder) => ({
    login: builder.mutation<DataRTKReturn<IResponseLogin>, IRequestLogin>({
      query: (content) => ({
        url: `/account/login`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account'],
      transformErrorResponse: (response:IResponseError<IDataMessage>, meta, arg) =>{
        return response.data?.Message
      }
    }),
    resetPasswordByCode: builder.mutation<DataRTKReturn<IResponseEx>, RequestLoginUserCode>({
      query: (content) => ({
        url: `/account/reset-password`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account'],
      transformErrorResponse: (response:IResponseError<IDataMessageWithErrors>, meta, arg) =>{
        return response.data?.MessageWithErrors
      }
    }),
    logout: builder.mutation<DataRTKReturnList<IResponseEx>, undefined>({
      query: () => ({
        url: `/account/logout`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account']
    }),
    resetPassword: builder.mutation<DataRTKReturnList<IResponseEx>, IRequestResetPassword>({
      query: (content) => ({
        url: `/activate-account/reset-password`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account'],
      transformErrorResponse: (response:IResponseError<IDataMessageWithErrors>, meta, arg) =>{
        return response.data?.MessageWithErrors
      }
    }),
    confirmEmail: builder.mutation<DataRTKReturn<IResponseConfirmEmail>, IRequestConfirmEmail>({
      query: (content) => ({
        url: `/activate-account/confirm-email`,
        body: JSON.stringify(content),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account'],
      transformErrorResponse: (response:IResponseError<IDataMessageWithErrors>, meta, arg) =>{
        return response.data?.MessageWithErrors
      }
    }),
    forgotPassword: builder.query<IDefaultResponse, string>({
      query: (username) => `/account/forgot-password/${username}`,
      transformErrorResponse: (response:IResponseError<IDataStatusCodeError>, meta, arg) =>{
        return response.data?.StatusCode
      }
    }),
    requestLoginFacebook: builder.mutation<DataRTKReturn<ResponseLogin>, undefined>({
      query: () => ({
        url: `/account/request-login-facebook`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account']
    }),
    loginFacebook: builder.query<DataRTKReturn<IResponseLogin>, string>({
      query: (code) => `/account/login-facebook?code=${code}`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
    requestLoginLinkedin: builder.mutation<DataRTKReturn<ResponseLogin>, undefined>({
      query: () => ({
        url: `/account/request-login-linkedin`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account']
    }),
    loginLinkedin: builder.query<DataRTKReturn<IResponseLogin>, string>({
      query: (code) => `/account/login-linkedin?code=${code}`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
    requestLoginGoogle: builder.mutation<DataRTKReturn<ResponseLogin>, undefined>({
      query: () => ({
        url: `/account/request-login-google`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['account']
    }),
    loginGoogle: builder.query<DataRTKReturn<IResponseLogin>, string>({
      query: (code) => `/account/login-google?code=${code}`,
      transformErrorResponse: (response, meta, arg) =>{
        return response.data
      }
    }),
  })
})

export default accountApi
